import { Link } from "gatsby"
import React from "react"
import "./abonamente.css"
import { abonamenteContent } from "../../content/devino-partener/devinoPartenerContent"

function PartenerAbonamente() {
  return (
    <div className="th-abonamente">
      {abonamenteContent.map((item, index) => (
        <div key={index} className="th-abonament">
          <h4>{item.name}</h4>
          <p>{item.benefits}</p>
          <Link to={`/contact`} className={"th-abonament__cta-btn"}>
            <button className="btn btn-primary">Cere detalii</button>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default PartenerAbonamente
