import React from "react"
import "./devino-partener.css"
import PartenerAbonamente from "../../components/Devino-Partener/PartenerAbonamente"

const imgSection1 = require("../../assets/devino_partener_poza1.png")

function DevinoPartener() {
  return (
    <>
      <div className="devino-partener__page bg-white">
        <DevinoPartenerSection1 />
        <DevinoPartenerSection2 />
      </div>
    </>
  )
}

const DevinoPartenerSection1 = () => (
  <div className="devino-partener__wrapper-1">
    <div className="devino-partener-section1">
      <img src={imgSection1.default} alt="Devino Partener Poza 1" />
      <p>
        Produsele si serviciile LINDA sunt pregatite pentru a fi distribuite, integrate si achizitionate direct sau prin partenerii si integratorii nostri.
      </p>
      {/* <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua.adipiscing
        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p> */}
    </div>
  </div>
)

const DevinoPartenerSection2 = () => (
  <div className="devino-partener-section3-wrapper">
    <div className="devino-partener-section3">
      <h2
        className="devino-partener-section3__text"
        style={{ fontSize: "40px", fontFamily: "Poppins" }}
      >
        Devino partener
      </h2>
      <p
        className="devino-partener-section3__text"
        style={{
          fontFamily: "Poppins",
          fontWeight: "500",
          fontSize: "16px",
          color: "black",
          lineHeight: "34px",
        }}
      >
        Va invitam sa selectati categoria colaborarii preferate si sa ne trimiteti o cerere de oferta in vederea stabilirii unei solutii optime pentru afacerea dumneavoastra.
      </p>
    </div>

    <PartenerAbonamente />
  </div>
)

export default DevinoPartener
