export const abonamenteContent = [
    {
      name: "Partener",
      benefits:
        "Pentru toate produsele si serviciile noastre  stabilim parteneriate de incredere prin care oferim tehnologiile noastre cele mai moderne si eficiente. Astfel suntem permanent in cautare de parteneri pentru distributia, integrarea produselor si serviciilor noastre. Toti partenerii nostri vor beneficia de avantaje nete.",
    },
    {
      name: "Integrator",
      benefits:
        "Societatile integratoare pentru solutiile noastre sunt cele care au specialisti din domeniul industrial cu expertiza pentru produsele SCADA/HMI/PLC. Acestia vor beneficia de discounturi avantajoase, de cursuri si suport pentru a-si insusi usor modul de utilizare a produselor si serviciilor noastre",
    },
    {
      name: "End-User",
      benefits:
        "Invitam toti clientii potentiali sa testeze produsele si serviciile noastre pentru a beneficia de avantajele si calitatea acestora la preturi competitive. Toate informatiile pot fi gasite pe acest website cat si pe paginile partenerilor LINDA. Echipele noastre de specialisti vor asigura permanent suportul si mentenanta de care este nevoie pentru fiecare serviciu si produs in parte.",
    },
  ]